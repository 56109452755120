import { template as template_0a22ca3f63e5472197bc508bdede688c } from "@ember/template-compiler";
const WelcomeHeader = template_0a22ca3f63e5472197bc508bdede688c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
